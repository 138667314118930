import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'

// Auth Default Routes
const authChildRoutes = (prefix) => [
    {
        path: '',
        name: prefix + '.login',
        meta: { auth: false, name: 'Login' },
        component: () => import('@/views/_auth/SignIn.vue')
    },
    {
        path: 'register',
        name: prefix + '.register',
        meta: { auth: false, name: 'Register' },
        component: () => import('@/views/_auth/SignUp.vue')
    },
    {
        path: 'reset-password',
        name: prefix + '.reset-password',
        meta: { auth: false, name: 'Reset Password' },
        component: () => import('@/views/_auth/ResetPassword.vue')
    },
    {
        path: 'varify-email',
        name: prefix + '.varify-email',
        meta: { auth: false, name: 'Varify Email' },
        component: () => import('@/views/_auth/VarifyEmail.vue')
    },
    {
        path: 'lock-screen',
        name: prefix + '.lock-screen',
        meta: { auth: false, name: 'Lock Screen' },
        component: () => import('@/views/_auth/LockScreen.vue')
    }
]

// Admin
const adminRoutes = (prefix) => [
    {
        path: 'dashboard',
        name: prefix + '.dashboard',
        meta: { auth: true, name: 'HomeAdmin', isBanner: true },
        component: () => import('@/views/admin/DashboardPage.vue')
    },

    // LAPOR
    {
        path: 'input-issue',
        name: prefix + '.input-issue',
        meta: { auth: false, name: 'inputIssue', isBanner: true },
        component: () => import('@/views/admin/issue/inputIssue.vue')
    },
    {
        path: 'input-survey',
        name: prefix + '.input-survey',
        meta: { auth: false, name: 'inputSurvey', isBanner: true },
        component: () => import('@/views/admin/survey/inputSurvey.vue')
    },


    // ISU MANAGEMENT 
    {
        path: 'responden-issue',
        name: prefix + '.responden-issue',
        meta: { auth: true, name: 'respondenIssue', isBanner: false },
        component: () => import('@/views/admin/issue/respondenIssue.vue')
    },
    {
        path: 'category-issue',
        name: prefix + '.category-issue',
        meta: { auth: true, name: 'categoryIssue', isBanner: false },
        component: () => import('@/views/admin/issue/categoryIssue.vue')
    },


    // SURVEY MANAGEMENT
    {
        path: 'responden-survey',
        name: prefix + '.responden-survey',
        meta: { auth: true, name: 'respondenSurvey', isBanner: false },
        component: () => import('@/views/admin/survey/respondenSurvey.vue')
    },
    {
        path: 'daftar-survey',
        name: prefix + '.daftar-survey',
        meta: { auth: true, name: 'daftarSurvey', isBanner: false },
        component: () => import('@/views/admin/survey/daftarSurvey.vue')
    },

    // USER
    {
        path: 'register-user',
        name: prefix + '.register-user',
        meta: { auth: true, name: 'addUser', isBanner: false },
        component: () => import('@/views/admin/users/registerUser.vue')
    },
    {
        path: 'daftar-user',
        name: prefix + '.daftar-user',
        meta: { auth: true, name: 'daftarUser', isBanner: false },
        component: () => import('@/views/admin/users/daftarUser.vue')
    },

    // INBOX
    {
        path: 'inbox',
        name: prefix + '.inbox-msg',
        meta: { auth: true, name: 'inboxMsg', isBanner: true },
        component: () => import('@/views/admin/inbox/InboxPage2.vue')
    },

    // PROFILE
    {
        path: 'profile',
        name: prefix + '.user-profile',
        meta: { auth: true, name: 'profile', isBanner: false },
        component: () => import('@/views/admin/inbox/ProfilePage.vue')
    },

]

// Koordinator
const koordinatorRoutes = (prefix) => [
    {
        path: 'koordinator',
        name: prefix + '.dashboard',
        meta: { auth: true, name: 'HomeKoordinator', isBanner: true },
        component: () => import('@/views/koordinator/DashboardPage.vue')
    },

    // LAPOR
    {
        path: 'koordinator/input-issue',
        name: prefix + '.input-issue',
        meta: { auth: true, name: 'inputIssue', isBanner: true },
        component: () => import('@/views/koordinator/inputIssue.vue')
    },
    {
        path: 'koordinator/input-survey',
        name: prefix + '.input-survey',
        meta: { auth: true, name: 'inputSurvey', isBanner: true },
        component: () => import('@/views/koordinator/inputSurvey.vue')
    },

    // INBOX
    {
        path: 'koordinator/inbox',
        name: prefix + '.inbox-msg',
        meta: { auth: true, name: 'inboxMsg', isBanner: true },
        component: () => import('@/views/koordinator/InboxPage.vue')
    },
]

// Relawan
const relawanRoutes = (prefix) => [
    {
        path: 'relawan',
        name: prefix + '.dashboard',
        meta: { auth: true, name: 'HomeRelawan', isBanner: true },
        component: () => import('@/views/relawan/DashboardPage.vue')
    },

    // LAPOR
    {
        path: 'relawan/input-issue',
        name: prefix + '.input-issue',
        meta: { auth: true, name: 'inputIssue', isBanner: true },
        component: () => import('@/views/relawan/inputIssue.vue')
    },
    {
        path: 'relawan/input-survey',
        name: prefix + '.input-survey',
        meta: { auth: true, name: 'inputSurvey', isBanner: true },
        component: () => import('@/views/relawan/inputSurvey.vue')
    },


    // INBOX
    {
        path: 'relawan/inbox',
        name: prefix + '.inbox-msg',
        meta: { auth: true, name: 'inboxMsg', isBanner: true },
        component: () => import('@/views/relawan/InboxPage.vue')
    },
]

// Error
const errorRoutes = (prefix) => [
    // Error Pages
    {
        path: '404',
        name: prefix + '.404',
        meta: { auth: true, name: 'Error 404', isBanner: true },
        component: () => import('@/views/errors/Error404Page.vue')
    },
    {
        path: '500',
        name: prefix + '.500',
        meta: { auth: true, name: 'Error 500', isBanner: true },
        component: () => import('@/views/errors/Error500Page.vue')
    },
    {
        path: 'maintenance',
        name: prefix + '.maintenance',
        meta: { auth: true, name: 'Maintenance', isBanner: true },
        component: () => import('@/views/errors/MaintenancePage.vue')
    }
]

const routes = [


    // Auth Skins
    {
        path: '/',
        name: 'auth',
        component: () => import('../layouts/guest/BlankLayout.vue'),
        children: authChildRoutes('auth')
    },

    {
        path: '/',
        name: 'adminPage',
        component: () => import('../layouts/AdminLayout.vue'),
        children: adminRoutes('admin')
    },

    {
        path: '/',
        name: 'dashboardPage',
        component: () => import('../layouts/DefaultLayout.vue'),
        children: koordinatorRoutes('koordinator')
    },

    {
        path: '/',
        name: 'relawanPage',
        component: () => import('../layouts/DefaultLayout.vue'),
        children: relawanRoutes('relawan')
    },


    // Errors Pages
    {
        path: '/errors',
        name: 'errors',
        component: () => import('../layouts/guest/BlankLayout.vue'),
        children: errorRoutes('errors')
    }
]

const router = createRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes
})


export default router
